import React, { useState } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { FaHome, FaVideo, FaHandshake, FaUsers, FaMedal } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Logo from '../Assets/image 2.png';
import logo2 from '../Assets/image 392.png';

function Navbar() {
  const [nav, setNav] = useState(false);

  const handleNavToggle = () => {
    setNav(prev => !prev);
  };
  const user = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'));
  const userType = user?.userType 
  
  const isLoggedIn = !!user || false;
  return (
    <nav className='p-3 bg-purp font-poppins text-white shadow md:flex md:items-center md:justify-between'>
      <div className='flex justify-between items-center w-full md:w-1/3'>
        <Link to={isLoggedIn ? "/userpage" :"/"}>
          <img className='h-[60px] cursor-pointer' src={Logo} alt='Company Logo' />
        </Link>
        <span onClick={handleNavToggle} className='cursor-pointer md:hidden block'>
          {nav ? <AiOutlineClose size={24} /> : <AiOutlineMenu size={24} />}
        </span>
      </div>

      {/* Desktop Links */}
      <ul className='hidden md:flex md:items-center md:justify-center w-full'>
        <Link to={isLoggedIn ? "/userpage" :"/"} className='mx-4 my-6 md:my-0 text-xl duration-500 hover:text-gray-400'>Home Page</Link>
        <Link to='/videopage' className='mx-4 my-6 md:my-0 text-xl duration-500 hover:text-gray-400'>Latest Videos</Link>
        <Link to='/sessionpage' className='mx-4 my-6 md:my-0 text-xl duration-500 hover:text-gray-400'>Book Session</Link>
        <a href='https://chat.whatsapp.com/HAP1HqYMtLI6rZGploazja' target='_blank' rel='noopener noreferrer' className='mx-4 my-6 md:my-0 text-xl duration-500 hover:text-gray-400'>Join Our Community</a>
        <Link to='/userleaderboard' className='mx-4 my-6 md:my-0 text-xl duration-500 hover:text-gray-400'>Leader Board</Link>
        {
          userType === "Admin" && <Link to='/uploadvideo' className='mx-4 my-6 md:my-0 text-xl duration-500 hover:text-gray-400'>Upload Video</Link>
        }
      </ul>

      {/* Desktop Auth Links */}
      {!isLoggedIn && (
        <div className='hidden md:flex justify-center items-center md:w-1/3'>
          <ul className='flex'>
            <Link to='/login' className='mx-4 my-6 md:my-0 text-xl duration-500 border rounded-lg px-4 md:px-6 hover:text-gray-400'>Log In</Link>
            <Link to='/signup' className='mx-4 my-6 md:my-0 text-xl duration-500 bg-dark rounded-lg md:px-6 hover:text-gray-400'>Sign Up</Link>
          </ul>
        </div>
      )}

      {/* Mobile Navigation */}
      <ul
        className={`fixed top-0 left-0 w-2/3 bg-bluebg h-full border-r p-6 border-gray-300 z-50 transform ${nav ? 'translate-x-0 opacity-100' : '-translate-x-full opacity-0'
          } transition-transform duration-300 ease-in-out md:hidden`}
      >
        <li className='mb-8'>
          <img src={logo2} alt='Secondary Logo' className='w-24' />
        </li>
        <Link to='/' className='mx-4 my-6 text-xl hover:bg-lightblue hover:text-bluebg border-b-2 border-gray-500 p-2 flex items-center'>
          <FaHome className='mr-2' /> Home Page
        </Link>
        <Link to='/videopage' className='mx-4 my-6 text-xl hover:bg-lightblue hover:text-bluebg border-b-2 border-gray-500 p-2 flex items-center'>
          <FaVideo className='mr-2' /> Latest Videos
        </Link>
        <Link to='/sessionpage' className='mx-4 my-6 text-xl hover:bg-lightblue hover:text-bluebg border-b-2 border-gray-500 p-2 flex items-center'>
          <FaHandshake className='mr-2' /> Book Session
        </Link>
        <a href='https://chat.whatsapp.com/HAP1HqYMtLI6rZGploazja' target='_blank' rel='noopener noreferrer' className='mx-4 my-6 text-xl hover:bg-lightblue hover:text-bluebg border-b-2 border-gray-500 p-2 flex items-center'>
          <FaUsers className='mr-2' /> Join Our Community
        </a>
        <Link to='/userleaderboard' className='mx-4 my-6 text-xl hover:bg-lightblue hover:text-bluebg border-b-2 border-gray-500 p-2 flex items-center'>
          <FaMedal className='mr-2' /> Leader Board
        </Link>
        
        {/* Mobile Auth Links */}
        {!isLoggedIn && (
          <>
            <Link to='/login' className='mx-4 my-6 text-xl hover:bg-lightblue hover:text-bluebg border-b-2 border-gray-500 p-2'>Log In</Link>
            <Link to='/signup' className='mx-4 my-6 text-xl hover:bg-lightblue hover:text-bluebg border-b-2 border-gray-500 p-2'>Sign Up</Link>
          </>
        )}
      </ul>
    </nav>
  );
}

export default Navbar;
